

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0 10px 0;
}

.divider:before,
.divider:after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider:before {
  margin-right: 10px;
}

.divider:after {
  margin-left: 10px;
}

.divider p {
  font-size: 16px;
  color: #888;
  margin: 0;
  margin-top: -3px;
  padding: 0;
}
